"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getImageSize = void 0;
const getImageSize = (url, options = {}) => {
    return new Promise((resolve, reject) => {
        if (typeof window === 'undefined') {
            return reject('Window is not defined');
        }
        if (!url) {
            return reject('Url is not defined');
        }
        let timer = null;
        const img = new Image();
        img.addEventListener('load', () => {
            if (timer) {
                clearTimeout(timer);
            }
            resolve({ width: img.naturalWidth, height: img.naturalHeight });
        });
        img.addEventListener('error', (event) => {
            if (timer) {
                clearTimeout(timer);
            }
            reject(`${event.type}: ${event.message}`);
        });
        img.src = url;
        if (options.timeout) {
            timer = setTimeout(() => reject('Timeout'), options.timeout);
        }
    });
};
exports.getImageSize = getImageSize;
